import React from 'react'
import Typed from 'typed.js'

class TypedText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blockerDetected: false,
    }

    this.detect = this.detect.bind(this)
    this.makeTypings = this.makeTypings.bind(this)
  }

  componentDidMount() {
    this.detect()
  }

  componentDidUpdate(prevProps) {
    if (this.props.pathname !== prevProps.pathname) {
      this.detect()
    }
    if (this.state.blockerDetected) {
      this.makeTypings()
    }
  }

  componentWillUnmount() {
    this.typed.destroy()
  }

  detect() {
    const url = 'adserver.com/ad.js'
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    try {
      http.send()
    } catch (error) {
      console.log('adblock detected')
      this.setState({ blockerDetected: true })
    }

    this.makeTypings()
  }

  makeTypings() {
    if (this.typed) {
      this.typed.destroy()
    }

    const time = new Date()
    const hour = time.getHours()
    var greeting = 'Good Afternoon'
    var typings = []
    if (hour < 12) {
      greeting = 'Good Morning'
    }
    if (hour > 17) {
      greeting = 'Good Evening'
    }
    typings.push(greeting)

    if (this.state.blockerDetected) {
      typings.push('It looks like you have an ad blocker enabled . . .  Nice!')
    }

    const options = {
      strings: typings,
      typeSpeed: 100,
      backDelay: 6000,
      startDelay: 900,
      loop: true,
      loopCount: false,
      cursorChar: '|',
    }

    // this.el refers to the <span> in the render() method
    this.typed = new Typed(this.el, options)
  }

  render() {
    return (
      <div
        className=""
        style={{
          position: 'absolute',
          textAlign: 'center',
          left: 0,
          right: 0,
          margin: 'auto',
        }}
      >
        <div className="intro-body"></div>
        <div className="container">
          <div className="row">
            <div
              className=""
              style={{
                position: 'absolute',
                textAlign: 'center',
                left: 0,
                right: 0,
                margin: 'auto',
                paddingTop: '25%'
              }}
            >
              <span
                style={{ whiteSpace: 'pre-wrap' }}
                ref={el => {
                  this.el = el
                }}
                className="intro-text"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default TypedText
