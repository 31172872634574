import React from 'react'
import { Link } from 'gatsby'

class Navi extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollToElement)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollToElement)
  }

  handleScrollToElement() {
    $('.navbar').offset().top
      ? $('.navbar-fixed-top').addClass('top-nav-collapse')
      : $('.navbar-fixed-top').removeClass('top-nav-collapse')
  }

  render() {
    const { isHome } = this.props
    return (
      <>
        <div
          className="navbar container"
          id="nav-buttons"
          style={{ display: 'none' }}
        >
          <br />
          <div className="menu-link">
            <a data-cy="about-link-mobile" href="/#about">
              About
            </a>
          </div>
          <div className="menu-link">
            {isHome ? (
              <a href="/#contact" data-cy="contact-link-mobile">
                {' '}
                Contact{' '}
              </a>
            ) : (
              <Link to="/#contact" className="nav-link">
                Resume
              </Link>
            )}
          </div>
          <div className="menu-link">
            <Link
              to="/resume/"
              data-cy="resume-link-mobile"
              className="nav-link"
            >
              Resume
            </Link>
          </div>
          <div className="menu-link">
            <Link
              to="/pastWorks/"
              data-cy="past-works-link-mobile"
              className="nav-link"
            >
              Past Works
            </Link>
          </div>
        </div>

        <nav
          className="navbar navbar-custom navbar-fixed-top"
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header">
              <button
                className="navbar-toggle"
                data-target=".navbar-main-collapse"
                data-toggle="collapse"
                type="button"
                aria-label="Toggle Nav button"
              >
                <i className="fa fa-bars" data-cy="mobile-nav"></i>
              </button>
              <a
                className="navbar-brand page-scroll"
                href="https://nicktorres.net"
              >
                <div>Nick Torres</div>
              </a>
            </div>
            <div className="collapse navbar-collapse navbar-right navbar-main-collapse">
              <ul className="nav navbar-nav" id="nav-links">
                <li>
                  {isHome ? (
                    <a
                      data-cy="about-link"
                      className="page-scroll"
                      href="/#about"
                    >
                      {' '}
                      About{' '}
                    </a>
                  ) : (
                    <Link
                      to="/#about"
                      data-cy="about-link"
                      className="nav-link"
                    >
                      About
                    </Link>
                  )}
                </li>
                <li>
                  {isHome ? (
                    <Link
                      to="/#contact"
                      data-cy="contact-link"
                      className="nav-link"
                    >
                      Contact
                    </Link>
                  ) : (
                    <Link
                      to="/#contact"
                      data-cy="contact-link"
                      className="nav-link"
                    >
                      Contact
                    </Link>
                  )}
                </li>
                <li>
                  <Link
                    to="/resume/"
                    data-cy="resume-link"
                    className="nav-link page-scroll"
                  >
                    Resume
                  </Link>
                </li>
                <li>
                  <Link
                    to="/pastWorks/"
                    data-cy="past-works-link"
                    className="nav-link page-scroll"
                  >
                    Past Works
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )
  }
}

export default Navi
