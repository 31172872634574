import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react'
import TypedText from '../typed'
import { StaticQuery, graphql } from 'gatsby'

export default function Jumbo () {
  return (<StaticQuery
    query={graphql`
    query JumboQuery {
        background: file(name: { eq: "bg2" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
            )
          }
        }
      }
    `}
    render={data =>  (
      <div data-cy="jumbo-component">
        <TypedText style={{ zIndex: 5 }}></TypedText>
        <GatsbyImage
          image={data.background.childImageSharp.gatsbyImageData}
          style={{ zIndex: '-1' }}
          alt="background image of 2 people in a canoe"
        ></GatsbyImage>
      </div>
    )}
  />)
}
