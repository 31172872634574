import React from 'react'
import emergence from 'emergence.js'
import Navi from 'components/navi'
import Footer from 'components/footer'
import { siteMetadata } from '../../../gatsby-config'
import Jumbo from 'components/jumbo'
import { Helmet } from "react-helmet"
import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'

class Layout extends React.Component {
  componentDidMount() {
    emergence.init()
  }

  componentDidUpdate() {
    emergence.init()
  }

  render() {
    const { children, isHome, color } = this.props
    const backgroundColor = color ? color : ''
    return (
      <div style={{ backgroundColor: backgroundColor }}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nick's Site</title>
        </Helmet>
        <Navi title={siteMetadata.title} {...this.props} />
        <div>
          {isHome ? <Jumbo/> : <></>}
          <br></br>
        </div>
        <main>
          <div style={{ margin: '10px' }}>{children}</div>
        </main> 
        <Footer title={siteMetadata.title} author={siteMetadata.author} />
      </div>
    )
  }
}

export default Layout
